@import url(https://fonts.googleapis.com/css2?family=Ubuntu&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat&display=swap);
html,
body {
  max-width: 100%;
  overflow-x: hidden;
  scroll-behavior: smooth;
  overflow: scroll;
  font-family: "Josefin Sans", sans-serif;

  -moz-osx-font-smoothing: grayscale;
  -webkit-overflow-scrolling: touch;
}

#root {
  overflow: hidden;
  opacity: 100000;
}

.processIcon {
  height: 14em;
  width: auto;
  margin-bottom: 0.5em;
}

.callNow {
  margin-bottom: 1em !important;
  margin-top: 1em;
}

.primaryButton {
  color: white;
  background-color: #858be5;
  z-index: 1;
  font-size: 1.35em;
  padding: 0.5em 2.5em;
  border-radius: 30px;
  text-transform: none;
  font-family: "Josefin Sans", sans-serif !important;
  text-decoration: none;
}

.primaryButton hover {
  background-color: "#6771FF";
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.boxShadow {
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}

.boxShadow:hover {
  box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22);
}

.mobileProcessText {
  text-transform: none;
  font-family: "Josefin Sans", sans-serif !important;
  text-decoration: none;
  padding-right: 2.25em;
  padding-left: 2.25em;
}

.mobileProcessText h1 {
  font-size: 1.5em;
}
.mobileProcessText h2 {
  font-size: 1em;
  margin-top: 1.5em;
  margin-bottom: 1.5em;
}

.mobileAboutText {
  text-transform: none;
  font-family: "Josefin Sans", sans-serif !important;
  text-decoration: none;
}

.mobileAboutText h1 {
  font-size: 1.5em;
}
.mobileAboutText h2 {
  margin-top: 1em;
  margin-bottom: 2em;
  font-size: 1em;
  padding-left: 2em;
  padding-right: 2em;
}

.heroHeader {
  font-size: 2.5em;
  font-weight: 700;
}
.heroSubHeader {
  font-size: 1em;
  font-weight: 700;
}

.sliderItems {
  margin-top: 3em;
  margin-bottom: 3em;
}

.testimonialsSection {
  margin-top: 10em;
  /* height: 65vh; */
}

.socialIcon {
  height: 1.5em !important;
  width: 1.5em !important;
  margin: 0.25em;
}

.testimonials h1 {
  font-size: 2.25em;
  font-weight: 700;
  color: white;
}

.testimonials h2 {
  font-size: 1.5em;
  color: white;
}

.sliderComponent h3 {
  font-size: 1em;
}

.blackText {
  text-transform: none;
  font-family: "Josefin Sans", sans-serif !important;
  color: black !important;
}

.whiteText {
  text-transform: none;
  font-family: "Josefin Sans", sans-serif !important;
  color: white !important;
}

.roundedDiv {
  border-radius: 30px;
}

.active {
  background-color: gray;
  color: red;
}

.textCenter {
  text-align: center;
}

.longIslandImage {
  height: 6em;
  width: 15em;
}

.instagramSection {
  margin-top: 7em;
  height: 117vh;
}

.containBackground {
  background-repeat: no-repeat;
  background-size: contain;
}

.ourProcessSection {
  margin-top: 10em;
  margin-bottom: 3em;
  height: 120vh;
}

.sleekGrid {
  padding-left: 2em;
  padding-right: 2em;
}

.coverBackground {
  background-repeat: no-repeat;
  background-size: cover;
}

.blobContainerContain {
  background-repeat: no-repeat !important;
  background-size: contain;
  display: flex;
  align-items: center;
}

.blobContainerCover {
  background-repeat: no-repeat !important;
  background-size: cover;
  display: flex;
  align-items: center;
}

.backgroundRight {
  background: right !important;
}

.verticalDiv {
  display: flex;
  align-items: center;
}

.verticalHorizontalDiv {
  display: flex;
  align-items: center;
  justify-content: center;
}

.verticalRightDiv {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.page-header {
  min-height: 100vh;
  max-height: 999px;
  padding: 0;
  color: #fff;
  position: relative;
  overflow: hidden;
}

.welcomeSection {
  margin-top: 5em;
  margin-bottom: 25em;
}

.page-header .page-header-image {
  position: absolute;
  background-size: cover;
  background-position: 50%;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.section-heading {
  font-size: 2.5em;
  text-align: center;
  line-height: 75px;
  font-family: "Josefin Sans", sans-serif;
}
.underline {
  width: 135px;
  border-bottom: 2px solid #668464;
  margin: auto;
}
@media only screen and (max-width: 450px) {
  .section-heading {
    font-size: 25px;
    line-height: 55px;
    padding: 0 2%;
  }
  .underline {
    width: 100px;
  }
}

/* NAVIGATION BAR CLASSES */
.navigation a {
  text-transform: none;
  font-weight: bold;
  font-size: 1.25em;
  font-family: "Josefin Sans", sans-serif;
}
.navigation button {
  text-transform: none;
  font-family: "Josefin Sans", sans-serif;
}

.servicesSection {
  /* background-color: #8cc0a6; */
  margin-top: 8em;
  margin-bottom: 8em;
  height: 120vh;
}

.sectionText {
  text-align: left !important;
}

.sectionLeftText {
  padding-left: 2em;
  padding-right: 2em;
  padding-top: 1em;
  text-align: left;
}

.maintenanceGridTiles {
  font-family: "Josefin Sans", sans-serif;
  min-height: 275px;
  height: 275px;
  min-width: 150px;
  padding: 2em;
  flex-direction: column !important;
  text-align: left !important;
  justify-content: left;
  color: white !important;
}

.maintenanceGridTiles h2 {
  font-size: 1.5em;
  font-weight: 700 !important;
  margin: 1em;
}

.maintenanceGridTiles p {
  margin: 1em;
}

.maintenanceSection {
  height: 235vh;
  margin: 1em;
  margin-bottom: 3em;
}

.servicesGridTitles {
  font-family: "Josefin Sans", sans-serif;
  min-height: 150px;
  min-width: 150px;
  padding: 2em;
}

.servicesGridTitles h2 {
  font-size: 1.5em;
}
/* END NAVIGATION BAR CLASSES */

/* HERO SECTION CLASSES */
.hero-section-body {
  padding-top: 13%;
  margin-left: 5%;
  width: 50%;
  text-align: left;
  text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
}
.hero-section-body h1 {
  color: black;
  text-align: center;
  font-size: 70px;
  margin-top: 12%;
  margin-bottom: 25px;
  font-family: "Josefin Sans", sans-serif;
}
.hero-section-body h2 {
  color: black;
  text-align: center;
  font-size: 20px;
  font-family: "Josefin Sans", sans-serif;
  font-weight: bold;
}
.hero-section-body img {
  margin-top: 65px;
  height: 145px;
}
.hero-section-body .btn-container {
  margin-top: 45px;
  text-align: center;
}

.svgTop {
  top: -2px;
  height: 100%;
  width: 101vw;
  justify-content: center;
  position: absolute;
  transform: translateZ(0);
}

@media only screen and (max-width: 960px) {
  .hero-section-body {
    text-align: center;
    width: 100%;
    margin: 15% 0;
    padding-left: 2%;
    padding-right: 2%;
  }

  .hero-section-body h1 {
    font-size: 60px;
  }

  .hero-section-body h2 {
    font-size: 18px;
  }
}

@media only screen and (max-width: 450px) {
  .hero-section-body {
    margin: 35% 0;
  }

  .hero-section-body h1 {
    font-size: 50px;
  }

  .hero-section-body h2 {
    font-size: 16px;
  }
}
/* END HERO SECTION CLASSES */

/* FEATURES HEADING CLASSES */

.features-heading {
  font-family: "Josefin Sans", sans-serif;
}

.features-heading {
  width: 100%;
  text-align: center;
}
.features-heading img {
  max-height: 75px;
}
.features-heading h2 {
  margin-top: 50px;
  font-family: "Josefin Sans", sans-serif;
  font-size: 20px;
  font-weight: normal;
}

.aboutSection {
  height: 100%;

  background-color: rgb(61, 153, 112, 0.2);
  margin-top: 5em;
}

.borderBoxOuter {
  padding: 0.75em;
  height: 100%;
  width: 95%;
}

.form {
  margin-top: 3em;
}

.desktopPhoneNumber {
  color: black !important;
  padding: 0px !important;
  font-size: 1.5em;
  display: block;
}

.contactText {
  width: 100%;
  height: 60%;
  margin-top: 3em;
  text-align: center !important;
}

.phoneNumberContact {
  font-size: 1.5em;
  padding: 0px !important;
}

.phoneNumber {
  font-size: "18px";
  font-weight: "700";
  padding: 1em;
  color: white;
  font-family: "Josefin Sans", sans-serif;
}

.borderBoxInner {
  display: flex;
  align-self: stretch;
  height: 100%;
  position: relative;
  width: 100%;
  border-width: 1em;
  border-style: solid;
  background-position: "center center";
  box-shadow: "0px 0px 0px 0px";
  top: 0px;
  float: right;
  background-size: cover;
  background-repeat: no-repeat;
}

@media only screen and (max-width: 450px) {
  .features-heading h2 {
    margin-top: 35px;
    margin-bottom: 45px;
    font-size: 17px;
  }
}
/* END FEATURES HEADING CLASSES */

/* FEATURES GRID CLASSES */
.features-grid {
  padding-left: 2%;
  padding-right: 2%;
  margin-top: 7%;
}
/* END FEATURES GRID CLASSES */

/* FEATURES CLASSES */
.features {
  padding: 0 2%;
}
/* END FEATURES CLASSES */

/* FEATURE SELECTOR CLASSES */
.feature-selector {
  margin: 5% 2%;
}
.feature-selector .feature-selected {
  background: #f5f5f5;
  box-shadow: 0px 2px 4px rgba(102, 132, 100, 0.25);
  border-radius: 20px;
  padding: 25px;
  margin: 25px;
}
.feature-selector .feature-selected h1 {
  color: #668464;
  font-size: 26px;
  font-family: "Josefin Sans", sans-serif;
  font-weight: bold;
}
.feature-selector .feature-selected p {
  font-family: "Josefin Sans", sans-serif;
  font-size: 16px;
}
.feature-selector .feature-unselected {
  transition: all 0.1s linear;
  padding: 25px;
  margin: 15px 10px;
}
.feature-selector .feature-unselected:hover {
  margin-left: 20px;
  cursor: pointer;
  transition: all 0.1s linear;
}
.feature-selector .feature-unselected h1 {
  font-size: 26px;
  font-family: "Josefin Sans", sans-serif;
  font-weight: bold;
}
.feature-selector img {
  width: 100%;
  transition: opacity 0.4s linear;
}
@-webkit-keyframes fadeEffect {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeEffect {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@media only screen and (max-width: 960px) {
  .feature-selector .feature-selected {
    border-radius: 20px;
    padding: 15px;
    margin: 25px 0px;
  }
}

@media only screen and (max-width: 450px) {
  .feature-selector .feature-selected {
    border-radius: 10px;
    padding: 10px;
    margin: 25px 0px;
  }
  .feature-selector .feature-selected h1 {
    font-size: 19px;
  }

  .feature-selector .feature-selected p {
    font-size: 14px;
  }
}
/* END FEATURE SELECTOR CLASSES */

/* FEATURE CLASSES */
.feature {
  text-align: center;
  padding: 5%;
}
.feature img {
  height: 225px;
  padding-bottom: 10px;
}
.feature h1 {
  font-family: "Josefin Sans", sans-serif;
  font-size: 20px;
  font-weight: bold;
}
.feature p {
  font-family: "Josefin Sans", sans-serif;
  font-size: 16px;
}
/* END FEATURE CLASSES */

/* ABOUT US CLASSES */
.about-us {
  text-align: center;
  margin-top: 250px;
}
.about-us .top-image {
  height: 175px;
  margin-bottom: -87.5px;
  padding-left: 45px;
}
.about-us .content {
  padding-top: 150px;
  padding-bottom: 150px;
}
.about-us .logo {
  height: 160px;
  margin: 50px 0px;
}
.about-us .avocado {
  position: absolute;
  height: 165px;
  left: 55px;
}
.about-us .eggs {
  position: absolute;
  height: 165px;
  left: 55px;
}
.about-us .lemons {
  position: absolute;
  height: 650px;
  right: -80px;
}
.about-us p {
  color: #002d02;
  font-family: "Josefin Sans", sans-serif;
  font-size: 20px;
  font-weight: bold;
  text-align: justify;
  width: 70%;
  max-width: 800px;
  padding-top: 10px;
  padding-bottom: 55px;
  margin: auto;
}

@media only screen and (max-width: 450px) {
  .about-us {
    margin-top: 100px;
  }
  .about-us .content {
    padding-top: 100px;
    padding-bottom: 150px;
  }
  .about-us .top-image {
    height: 125px;
    margin-bottom: -62.5px;
    padding-left: 45px;
  }
  .about-us .logo {
    height: 100px;
    margin: 30px 0px;
  }

  .about-us p {
    font-size: 16px;
    width: 80%;
  }
}
/* END ABOUT US CLASSES */

/* COVID OFFER CLASSES */
.covid-offer {
  text-align: center;
  padding: 15% 5%;
}
.covid-offer img {
  max-width: 300px;
  width: 100%;
  padding: 5%;
}
.covid-offer p {
  font-family: "Josefin Sans", sans-serif;
  font-size: 18px;
  text-align: justify;
  padding: 20px 5%;
}
.covid-offer .container {
  text-align: center;
  width: 100%;
}
@media only screen and (max-width: 960px) {
  .covid-offer img {
    max-width: 225px;
  }
}
@media only screen and (max-width: 450px) {
  .covid-offer p {
    font-size: 16px;
    text-align: justify;
    padding: 15px 0;
  }
}
/* END COVID OFFER CLASSES */

/* OUR BLOG SECTION CLASSES */
.our-blog {
  text-align: center;
  padding: 0% 4%;
}
.our-blog .helper {
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}
.our-blog .onions {
  vertical-align: middle;
}
.our-blog .cutting-board {
  width: 100%;
  max-width: 950px;
  margin-right: -50px;
}
.our-blog .btn-container {
  margin-top: 2%;
  text-align: center;
}
@media only screen and (max-width: 450px) {
  .our-blog .cutting-board {
    margin-right: -20px;
    max-width: 350px;
  }
}
/* END OUR BLOG SECTION CLASSES */

/* CONTACT CLASSES */
.contact {
  margin-top: 5%;
  min-height: 100vh;
  max-height: 999px;
  padding: 0;
  color: #fff;
  position: relative;
  overflow: hidden;
  background-image: url("/assets/table.png");
  background-size: cover;
  background-position: 50%;
}
.contact .content {
  margin-top: 15%;
  text-align: center;
  font-family: "Josefin Sans", sans-serif;
}
.contact .content h1 {
  font-size: 45px;
  margin-bottom: 55px;
}
.contact .content form {
  padding: 0 15%;
}
@media only screen and (max-width: 450px) {
  .contact .content h1 {
    font-size: 35px;
    margin-top: 75px;
    margin-bottom: 45px;
  }
  .contact .content form {
    padding: 0 5%;
  }
}
/* END CONTACT CLASSES */

/* FOOTER CLASSES */
.footer-separator {
  width: 235px;
  border-bottom: 2px solid #668464;
  margin: auto;
  margin-top: 50px;
  margin-bottom: 25px;
}
.footer {
  padding: 15px 2%;
  font-family: "Josefin Sans", sans-serif;
}
.footer .left {
  text-align: left;
}
.footer .center {
  text-align: center;
  margin-bottom: 15px;
}
.footer .center a {
  color: black;
  margin: 5px 15px;
  display: inline-block;
}
.footer .center a:hover {
  text-decoration: underline;
  cursor: pointer;
}
.footer .right {
  text-align: right;
}
.footer p {
  font-size: 18px;
  margin-bottom: 5px;
}
/* END FOOTER CLASSES */

/* Hubspot chat shadow container was messed up so just hide that garbage */
#hubspot-messages-iframe-container .shadow-container.active {
  display: none !important;
}

/* utils */

.svgBottom {
  /*background: url(../images/vectors/perspectiveBottom.svg) no-repeat bottom;*/
  width: 100%;
  height: 100%;
  bottom: -2px;
  background-size: 101% auto;
  z-index: 1;
  position: absolute;
  transform: translateZ(0);
  /*-webkit-transform: translateZ(0);
  -webkit-transform: translate3d(0, 0, 0);*/
}

@media screen and (min-width: 600px) {
  .maintenanceSection {
    height: 120vh;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-height: 800px) {
  .servicesSection {
    margin-top: 10em;
    margin-bottom: 10em;

    height: 150vh !important;
  }
}

/* large phones, tablets */
@media screen and (min-width: 768px) {
  .servicesSection {
    margin-top: 10em;
    height: 115vh;
  }

  .sectionLeftText {
    padding-left: 4em;
    padding-right: 4em;
    padding-top: 1em;
    text-align: left;
  }

  .servicesGridTitles {
    min-height: 250px;
    min-width: 250px;
    padding: 2em;
  }
}

/* smaller desktops */
@media screen and (min-width: 960px) {
  .borderBoxOuter {
    padding: 1em;
  }
  .borderBoxInner {
    border-width: 1.5em;
  }
  .longIslandImage {
    height: 10em;
    width: 25em;
  }

  .servicesGridTitles {
    padding: 0px;
  }
  .servicesGridTitles h2 {
    font-size: 2em;
  }
  .aboutSection {
    background-color: white;

    margin-top: 2em;
    height: 100%;
  }
  .ourProcessSection {
    margin-top: 10em;
    margin-bottom: 10em;
    height: 100vh;
  }

  .sliderComponent h3 {
    font-size: 1.5em;
  }

  .testimonials h1 {
    font-size: 4em;
  }

  .testimonials h2 {
    font-size: 2em;
  }

  .instagramSection {
    margin-top: 15em;
  }

  .testimonialsSection {
    margin-top: 3em;
    height: 110vh;
  }

  .testimonials p {
    font-size: 1.5em;
  }

  .contactText {
    width: 80%;
    height: 60%;
    text-align: left !important;
    margin-top: 3em;
  }
  .phoneNumberContact {
    font-size: 1.75em;
    padding: 0px !important;
  }
}

/* larger desktops */
@media screen and (min-width: 1200px) {
  .heroHeader {
    font-size: 4.5em;
    font-weight: 700;
  }
  .heroSubHeader {
    font-size: 2em;
    font-weight: 700;
  }
  .sleekGrid {
    padding-left: 10em;
    padding-right: 10em;
  }
}

.contactSection {
  height: 125vh !important;
}

@media only screen and (device-width: 414px) and (device-height: 736px) and (-webkit-device-pixel-ratio: 3) {
  .lillyBlobContainer {
    width: 63vw !important;
  }
  .servicesSection {
    height: 135vh !important;
  }
  .ourProcessSection {
    height: 135vh !important;
  }
  .maintenanceSection {
    height: 275vh !important;
  }
  .contactSection {
    height: 125vh !important;
  }
}

/* iphone 6/7/8 */
@media screen and (min-device-width: 360px) and (max-device-width: 390px) and (max-device-height: 700px) {
  .servicesSection {
    height: 155vh;
  }

  .ourProcessSection {
    height: 170vh;
  }

  .instagramSection {
    height: 180vh;
  }

  .maintenanceSection {
    height: 275vh;
  }

  .testimonialsSection {
    height: 85vh;
  }
}

/* iphone 6/7/8 plus */
@media only screen and (min-device-width: 414px) and (max-device-width: 736px) {
}

@media screen and (min-width: 400px) and (max-height: 740px) {
}

@media only screen and (min-device-width: 1200px) and (max-device-height: 800px) {
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.slick-slider {
  display: table !important;
  table-layout: fixed !important;
  width: 90% !important;
}

@media screen and (min-width: 960px) {
  .slick-slider {
    width: 70% !important;
  }
}

